import React from 'react';
import { graphql } from 'gatsby';
import Link from '@shared/components/link/link';
import Layout from '@shared/components/layout/layout';
import { useGlobalFooter } from '@shared/hooks/use-global-footer';
import { CIA_TEMPLATE } from '@shared/utils/constants';
import AboutDark from '@shared/svgs/glyphs/mission-dark';
import CareersDark from '@shared/svgs/glyphs/careers-dark';
import CSIDark from '@shared/svgs/glyphs/csi-dark';
import LegacyLight from '@shared/svgs/glyphs/legacy-light';
import ResourcesLight from '@shared/svgs/glyphs/resources-light';
import WorldFactBookDark from '@shared/svgs/glyphs/wfb-dark';
import StoriesLight from '@shared/images/Glyph_Stories_White.png';
import ButtonLink from '@shared/components/utils/button-link';

const SiteMap = ({ data }) => {
  const wfbPath = '/the-world-factbook/';
  const wfbPages = [
    { title: 'The World', uri: wfbPath + 'countries/world/' },
    { title: 'Explore All Countries', uri: wfbPath + 'countries/' },
    { title: 'Maps', uri: wfbPath + 'maps/' },
    { title: 'References', uri: wfbPath + 'references/' },
    { title: 'About', uri: wfbPath + 'about/' },
    { title: 'History', uri: wfbPath + 'about/history/' },
    { title: 'Frequently Asked Questions', uri: wfbPath + 'about/faqs/' },
    { title: 'Archives', uri: wfbPath + 'about/archives/' },
    { title: 'Flags of the World', uri: wfbPath + 'references/flags-of-the-world/' },
    { title: 'Whats New', uri: wfbPath + 'about/whats-new/' },
  ];
  const CSIPages = [
    { title: 'CSI', uri: 'resources/csi/' },
  ];
  const leadersPath = '/resources/world-leaders/';
  const leadersPages = [
    { title: 'Explore All Foreign Governments', uri: leadersPath + 'foreign-governments/' },
    { title: 'Key to Abbreviations', uri: leadersPath + 'key-to-abbreviations/' },
    { title: 'Historical Data', uri: leadersPath + 'historical-data/' },
  ];
  const careersPath = '/careers/';
  const careersPages = [
    { title: 'Foreign Language Proficiency Scale', uri: careersPath + 'language-opportunities/foreign-language-proficiency-scale/' },
    { title: 'Foreign Language Incentive Program', uri: careersPath + 'language-opportunities/foreign-language-incentive-program/' },
    { title: 'Intelligence Language Institute', uri: careersPath + 'language-opportunities/intelligence-language-institute/' },
    { title: 'Language Opportunities', uri: careersPath + 'language-opportunities/' },
    { title: 'Accommodations', uri: careersPath + 'accommodations/' },
    { title: 'Veterans', uri: careersPath + 'working-at-cia/veterans/' },
    { title: 'Benefits', uri: careersPath + 'working-at-cia/benefits/' },
    { title: 'Working at CIA', uri: careersPath + 'working-at-cia/' },
    { title: 'CIA’s Hiring Process', uri: careersPath + 'cias-hiring-process/' },
    { title: 'CIA Requirements', uri: 'cia-requirements/' },
    { title: 'How We Hire', uri: careersPath + 'how-we-hire/' },
    { title: 'Student Programs', uri: careersPath + 'student-programs/' },
    { title: 'Saved Jobs', uri: careersPath + 'saved-jobs/' },
    { title: 'Browse CIA Jobs', uri: careersPath + 'jobs/' },
    { title: 'Diversity and Inclusion', uri: careersPath + 'working-at-cia/diversity-and-inclusion/' },
  ];

  return (
    <Layout
      template={CIA_TEMPLATE}
      title={`Sitemap - ${data.site.siteMetadata.title}`}
      //headerMenu={data.header.menuItems.nodes}
      headerMenu={data?.header?.menuItems.nodes ?? data.cia.menuItems.nodes}
      footerMenu={useGlobalFooter()}
    >
      <section className="background-threads careers-threads">
        <div className="container pt90 pb60">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <h1 className="mb30">Sitemap</h1>
            </div>
          </div>
        </div>
      </section>
      <PageMap
        className={'background-grid-white'}
        icon={(<AboutDark />)}
        title='About CIA'
        uri='/about/'
        pages={data.about.nodes}
      />
      <PageMap
        icon={(<LegacyLight />)}
        title='Legacy'
        uri='/legacy/'
        pages={data.legacy.nodes}
      />
      <PageMap
        className={'background-grid-white'}
        icon={(<CareersDark />)}
        title='Careers'
        uri={careersPath}
        pages={careersPages}
      />
      <PageMap
        icon={(<ResourcesLight />)}
        title='Resources'
        uri='/resources/'
        pages={data.resources.nodes}
      />
      <PageMap
        className={'background-grid-white'}
        icon={(<CSIDark />)}
        title='Center for the Study of Intelligence (CSI)'
        uri='/resources/csi/'
        pages={CSIPages}
      />
      <PageMap
        icon={(<img src={StoriesLight} alt="Stories Light Icon" />)}
        title='News and Stories'
        uri='/stories/'
        pages={data.stories.nodes}
      />
      <PageMap
        className={'background-grid-white'}
        icon={(<WorldFactBookDark />)}
        title='The World Factbook'
        uri={wfbPath}
        pages={wfbPages}
      />
      <PageMap
        icon={(<AboutDark />)}
        title='World Leaders'
        uri={leadersPath}
        pages={leadersPages}
      />
    </Layout>
  );
};

const PageMap = ({ className = '', icon, title, uri, pages }) => {
  return (
    <section className={className}>
      <div className="container">
        <div className="row pv120-90 align-items-start">
          <div className="col-sm-12 col-md-6">
            <div className="icon-xlarge">
              <div className="icon-xlarge">
                {icon}
              </div>
            </div>
            <h2 className="pt30 col-sm-12 col-md-8 remove-gutters-h">{title}</h2>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="overview-content">
              {pages.map((page) => {
                return (
                  <p key={page.uri}>
                    <Link
                      className="text-button bold"
                      to={page.uri}
                    >
                      {page.title}
                    </Link>
                  </p>
                );
              })}
              <ButtonLink
                id={uri}
                text={`Go to ${title}`}
                title={title}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SiteMap;

export const pageQuery = graphql` 
  query SiteMapData {
    site {
      siteMetadata {
        title
      }
    }
    header: wordpressWpMenu(slug: {eq: "cia-menu"}) {
      ...Header
    }
    about: allWordpressWpTtAbout(filter: {uri: {ne: "/about/"}}) {
      nodes {
        title
        uri
      }
    }
    legacy: allWordpressWpTtLegacy(filter: {uri: {ne: "/legacy/"}}) {
      nodes {
        title
        uri
      }
    }
    resources: allWordpressWpTtResource(filter: {uri: {ne: "/resources/"}}) {
      nodes {
        title
        uri
      }
    }
    stories: allWordpressWpTtStories(filter: {uri: {ne: "/stories/"}}) {
      nodes {
        title
        uri
      }
    }
  }
`;
